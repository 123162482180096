$(document).ready(function () {
    setTimeout(function() {
        $('.alert').fadeOut('fast');
    }, 10000);

    let sliderTop = $('.index .slide-top'),
        sliderTopItem = sliderTop.find('.item-slider');
    sliderTopItem.eq(0).addClass('active');

    let dotItems = '';
    for (let i=0; i < sliderTopItem.length; i++) {
        dotItems += i === 0 ? '<div class="dot active"></div>' : '<div class="dot"></div>'
    }
    sliderTop.append('<div class="navigation">'+dotItems+'</div>');
    $(document).on('click','.index .slide-top .dot', function () {

        if (!$(this).hasClass('active')) {
            clearInterval(intSlider);
            nextMainSlide($(this).index());

            intSlider = setInterval(function () {
                let autoNext = $('.index .slide-top .dot.active').index() + 1;
                if (autoNext >= sliderTopItem.length) {
                    autoNext = 0
                }
                nextMainSlide(autoNext);
            }, 2500);
        }
    });

    let intSlider = setInterval(function () {
        let autoNext = $('.index .slide-top .dot.active').index() + 1;
        if (autoNext >= sliderTopItem.length) {
            autoNext = 0
        }
        nextMainSlide(autoNext);
    }, 2500);

    function nextMainSlide(next) {
        let old = sliderTop.find('.item-slider.active').index();
        sliderTopItem.eq(next).addClass('next');
        sliderTopItem.eq(old).removeClass('active');
        sliderTop.find('.dot').removeClass('active').eq(next).addClass('active');
        setTimeout(function () {
            sliderTopItem.eq(next).addClass('active').removeClass('next');
        }, 600);
    }

    $('.to-next-window').click(function () {
        let top = $(window).height();
        $('html, body').animate({scrollTop: top}, 500)
    });


    $('.alphabet .alphabet__arrow .alphabet__arrow-nav').click(function() {
        let next = $(this).hasClass('next') ? true : false;
        let index = $(this).parent().parent().parent().parent('.tab-pane').index();
        let paneCount = $('.alphabet .tab-content .tab-pane').length;
        let newIndex = next ? index+1 : index-1;

        if (newIndex >=  paneCount) {
            newIndex = 0;
        }
        if (newIndex < 0 ) {
            newIndex = paneCount-1;
        }

        $('.alphabet .alphabet__list .item-letter').eq(newIndex).find('a').trigger('click');

    });


    $('.to-top').click(function () {
        var body = $("html, body");
        body.stop().animate({scrollTop:0}, 500);
    });
    $('.menu-toggle').click(function () {
        $('.menu').toggleClass('show')
    });
    $('.close-menu').click(function () {
        $('.menu').removeClass('show')
    });
    $('.hide-menu').click(function () {
        $('.menu').removeClass('show')
    });

    $('.info-additional .btn-show .btn').click(function () {
        $(this).parent().parent('.info-additional').toggleClass('show').find('.all-text').slideToggle(500)
    });

    $(document).on('click', '*[data-modal]',function () {
        var idModal= '#'+$(this).attr('data-modal');
        if ($(idModal).data('bs.modal').isShown) {
            $(idModal).find('#modalContent').load($(this).attr('value'));
            document.getElementById('modalHeader').innerHTML = '<h4 class="modal-title">' + $(this).attr('data-title') + '</h4>' +
                '<button type="button" class="close" data-dismiss="modal">&times;</button>';
        } else {
            $(idModal).modal('show').find('#modalContent').load($(this).attr('value'));
            document.getElementById('modalHeader').innerHTML = '<h4 class="modal-title">' + $(this).attr('data-title') + '</h4>' +
                '<button type="button" class="close" data-dismiss="modal">&times;</button>';
        }
    });


    let listSlider = $('.screen-items_list .list.inline'),
        pagBtn = listSlider.find('.slide-pag .pag-btn'),
        itemSlide = listSlider.find('> div');
    let length = 4;

    if ( $(window).width() < 600 ) {
        length = 1;
    } else if ( $(window).width() < 900 ) {
        length = 2;
    } else if ( $(window).width() < 1280 ) {
        length = 3;
    }

    listSlider.attr('now',0);
    for (let i=0; i<length; i++) {
        itemSlide.eq(i).addClass('shown')
    }

    pagBtn.click(function () {

        pagBtn.css('pointer-events','none');        // << disable click

        itemSlide.removeClass('shown');

        let count = itemSlide.length,
            now = parseInt(listSlider.attr('now')),
            next = now,
            w = parseInt(itemSlide.eq(0).width()) + 20,
            length = 4;

        let ml = parseInt(itemSlide.eq(0).css('marginLeft'));

        if ( $(window).width() < 600 ) {
            length = 1;
        } else if ( $(window).width() < 900 ) {
            length = 2;
        } else if ( $(window).width() < 1280 ) {
            length = 3;
        }


        if ( $(this).hasClass('next') && now < count-length-1 ) {

            console.log('ml ml-old', ml);
            ml = ml - w;
            next++;
            console.log('ml ml-next', ml,w)

        } else if ( $(this).hasClass('prev') && now > 0 ) {
            console.log('ml ml-old', ml);
            ml = ml + w;
            next--;
            console.log('ml ml-prev', ml)
        }


        console.log('ml ml-final', ml);

        itemSlide.eq(0).animate({'marginLeft': ml}, 500);
        listSlider.attr('now',next);

        for (let i=next; i<next+length; i++) {
            itemSlide.eq(i).addClass('shown')
        }

        setTimeout(function () { pagBtn.css('pointer-events','auto') },500);
    });


    let gSlider = $('.list-gallery'),
        gSliderItem = gSlider.find('> div[data-key]'),
        gModal = $('.modal-gallery'),
        gModalClose = $('.modal-backdrop'),
        gModalImage = gModal.find('.image'),
        gModalBtn = gModal.find('.slide'),
        gIndex = null,
        gLength = gSliderItem.length;

    gSliderItem.click(function () {
        let img = $(this).find('img').attr('src');
        let name = $(this).find('img').data('name');
        let description = $(this).find('img').data('description');
        gModalImage.html('<img src="'+img+'" alt="" /><div class="name">'+name+'</div><div class="description">'+description+'</div>');
        gModal.addClass('show');
        $(this).addClass('active');
        gIndex = $(this).index();
    });
    gModalClose.click(function () {
        console.log('click');
        gModal.removeClass('show');
        gSliderItem.removeClass('active');
        gIndex = null
    });
    gModalBtn.click(function () {
        if ( $(this).hasClass('next')) {
            gIndex++;
        } else if ( $(this).hasClass('prev') ) {
            gIndex--;
        }

        if (gIndex < 0) {
            gIndex = gLength - 1;
        } else if (gIndex >= gLength) {
            gIndex = 0;
        }

        gSliderItem.eq(gIndex).trigger('click');

    });

    $(document).on('click','.additional-contact-info .btn-show',function () {
        $(this).parent().toggleClass('show').find('.text').slideToggle(500);

    })





});
